import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import React, { Suspense, useEffect, useState } from "react";
import Main from "../components/Main";
import { MACHINE_TYPE } from "../assets/constants/constants";
import { StoredValues, WeatherData } from "../assets/interfaces/Dashboard";

import {
  getMachineLocation,
  getWeatherService,
} from "../assets/APIFuctions/DashboardFunctions";
import HelperService from "../Services/HelperService";
import WebService from "../Services/WebService";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [site_id, setSiteId] = useState("");
  const [company_id, setCompanyId] = useState("");
  const [machine_id, setMachineId] = useState("");
  const [outdoorSensorId, setOutdoorSensorId] = useState("");
  const pathParts = location.pathname.split("/");
  useEffect(() => {
      setSiteId(pathParts[pathParts.length - 4]);
      setCompanyId(pathParts[pathParts.length - 3]);
      setMachineId(pathParts[pathParts.length - 2]);
      setOutdoorSensorId(pathParts[pathParts.length - 1]);
      localStorage.setItem(
        "recent_machine_id",
        pathParts[pathParts.length - 2]
      );
      console.log(
        pathParts[pathParts.length - 2],
        "pathParts[pathParts.length - 2]",
        location?.pathname
      );
    
  }, [location]);

  const Login = React.lazy(() => import("../components/Login/Login"));

  const Dashboardultimate = React.lazy(
    () => import("../components/Ultimate/Ultimate")
  );
  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );
  const Dashboardaverage = React.lazy(
    () => import("../components/Average/Average")
  );
  const SelectStation = React.lazy(
    () => import("../components/Login/SelectStation")
  );
  const ForgetPassword = React.lazy(
    () => import("../components/Login/ForgotPassword")
  );
  const OTPVerification = React.lazy(
    () => import("../components/Login/OtpVerification")
  );
  const ResetPassword = React.lazy(
    () => import("../components/Login/AdminSetPassword")
  );

  useEffect(() => {
    if (
      location.pathname !== "/login" &&
      !location.pathname.startsWith("/select-station/")
    ) {
      if (location?.pathname?.startsWith("/command-center/dashboard-")) {
        setLocalStorageAndNavigate(
          pathParts[pathParts.length - 2],
          "COMMAND_CENTER"
        );
      } else if (checkLocalStorageForDashboard()) {
        let storedLocalStorageData: StoredValues | null = {
          indoorSensorId: "",
          outdoorSensorId: "",
          longitude: "",
          lattitude: "",
          siteId: "",
          machineId: "",
          machineType: "",
          companyId: "",
          companyLogo: "",
          tag: "",
        };

        if (localStorage.getItem(`machine_data_${machine_id}`)) {
          try {
            let storedData = localStorage.getItem(`machine_data_${machine_id}`);
            if (storedData) storedLocalStorageData = JSON.parse(storedData);
          } catch (e) {
            storedLocalStorageData = null;
          }
        }
        let previousDashboardType = storedLocalStorageData?.machineType;

        const selectedSitedId = storedLocalStorageData?.siteId;
        const companyId = storedLocalStorageData?.companyId;
        const machindeId = storedLocalStorageData?.machineId;
        const outdoorSensorId = storedLocalStorageData?.outdoorSensorId;

        if (previousDashboardType === MACHINE_TYPE.ULTIMATE)
          navigate(
            `/dashboard-ultimate/${selectedSitedId}/${companyId}/${machindeId}/${outdoorSensorId}`
          );
        else if (previousDashboardType === MACHINE_TYPE.VANILA)
          navigate(
            `/dashboard-vanilla/${selectedSitedId}/${companyId}/${machindeId}/${outdoorSensorId}`
          );
        else if (previousDashboardType === MACHINE_TYPE.ULTRAPRO)
          navigate(
            `/dashboard-ultrapro/${selectedSitedId}/${companyId}/${machindeId}/${outdoorSensorId}`
          );
      } else if (
        location?.pathname?.startsWith("/dashboard-") &&
        pathParts[pathParts.length - 2]
      ) {
        setLocalStorageAndNavigate(
          pathParts[pathParts.length - 2],
          "DASHBOARD"
        );
      }
    }
  }, []);
  const checkLocalStorageForDashboard = () => {
    let flag = false;
    if (localStorage.getItem(`machine_data_${machine_id}`)) {
      flag = true;
    } else {
      flag = false;
    }
    return flag;
  };

  const setLocalStorageAndNavigate = (machineId1: any, type: string) => {
    if (machineId1) {
      return WebService.getAPI({
        action: `app/station-details?station_id=${machineId1}`,
        body: null,
      })
        .then((res: any) => {
          if (res?.result) {
            let obj: StoredValues = {
              indoorSensorId: res?.result?.indoorSensorId,
              outdoorSensorId: res?.result?.outdoorSensorId,
              longitude: res?.result?.longitude,
              lattitude: res?.result?.lattitude,
              siteId: res?.result?.siteId,
              machineId: res?.result?.id,
              machineType: res?.result?.machineType,
              companyId: res?.result?.companyId,
              companyLogo: res?.result?.companyLogo,
              tag: res?.result?.tag,
            };

            localStorage.setItem(
              `machine_data_${obj?.machineId}`,
              JSON.stringify(obj)
            );
            if (obj?.lattitude && obj?.longitude)
              getWeatherData(obj?.lattitude, obj?.longitude, res?.result?.id);

            if (type === "COMMAND_CENTER") {
              if (obj.machineType === MACHINE_TYPE.ULTIMATE)
                navigate(
                  `/command-center/dashboard-ultimate/${obj.siteId}/${obj.companyId}/${obj.machineId}/${obj.outdoorSensorId}`
                );
              else if (obj.machineType === MACHINE_TYPE.VANILA)
                navigate(
                  `/command-center/dashboard-vanilla/${obj.siteId}/${obj.companyId}/${obj.machineId}/${obj.outdoorSensorId}`
                );
              else if (obj.machineType === MACHINE_TYPE.ULTRAPRO)
                navigate(
                  `/command-center/dashboard-ultrapro/${obj.siteId}/${obj.companyId}/${obj.machineId}/${obj.outdoorSensorId}`
                );
            } else if (type === "DASHBOARD") {
              console.log("dashboard==>");

              if (obj.machineType === MACHINE_TYPE.ULTIMATE)
                navigate(
                  `/dashboard-ultimate/${obj.siteId}/${obj.companyId}/${obj.machineId}/${obj.outdoorSensorId}`
                );
              else if (obj.machineType === MACHINE_TYPE.VANILA)
                navigate(
                  `/dashboard-vanilla/${obj.siteId}/${obj.companyId}/${obj.machineId}/${obj.outdoorSensorId}`
                );
              else if (obj.machineType === MACHINE_TYPE.ULTRAPRO)
                navigate(
                  `/dashboard-ultrapro/${obj.siteId}/${obj.companyId}/${obj.machineId}/${obj.outdoorSensorId}`
                );
            }
          }
        })

        .catch((error: any) => {
          return error;
        });
    }
  };
  const getWeatherData = (lat: any, long: any, machineId: any) => {
    let data: WeatherData = {
      temperature: "",
      city: "",
      weatherDiscription: "",
      icon: "",
    };
    const currentDate = new Date().toISOString().split("T")[0];
    getWeatherService(lat, long).then((response: any) => {
      data = {
        temperature: (response?.data?.main?.temp - 273.15).toFixed(2),
        city: "",
        weatherDiscription: response?.data?.weather[0]?.description,
        icon: HelperService.getIconForWeather(response?.data?.weather[0]?.icon),
      };
      localStorage.setItem(
        `weatherDataCollectedDate_${machineId}`,
        currentDate
      );
    });
    getMachineLocation(lat, long).then((response: any) => {
      if (
        response &&
        response?.length > 0 &&
        response[0].address_components.length > 0
      ) {
        let locationData = response;
        let cityLocation: any = "";
        let part1 =
          locationData[0]?.address_components[0] &&
          locationData[0]?.address_components[0]?.long_name;
        let part2 =
          locationData[0]?.address_components[1] &&
          locationData[0]?.address_components[1]?.long_name;
        let part3 =
          locationData[0]?.address_components[2] &&
          locationData[0]?.address_components[2]?.long_name;

        cityLocation = [part1, part2, part3]
          .filter((part) => part !== undefined)
          .join(" ");

        // cityLocation = `${locationData[0]?.address_components[0]?.long_name},${locationData[0]?.address_components[1]?.long_name},${locationData[0]?.address_components[2]?.long_name}`;
        localStorage.setItem(
          `weatherAPIData_${machineId}`,
          JSON.stringify({ ...data, city: cityLocation })
        );
      }
    });
  };

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense fallback={<></>}>
                <Main />
              </Suspense>
            }
          >
            <Route
              path="/forget-password"
              element={
                <Suspense fallback={<></>}>
                  <ForgetPassword />
                </Suspense>
              }
            />
            <Route
              path="/otp-verification"
              element={
                <Suspense fallback={<></>}>
                  <OTPVerification />
                </Suspense>
              }
            />
            <Route
              path="/reset-password"
              element={
                <Suspense fallback={<></>}>
                  <ResetPassword />
                </Suspense>
              }
            />
            <Route
              path="/select-station/:company_id"
              element={
                <Suspense fallback={<></>}>
                  <SelectStation />
                </Suspense>
              }
            />
            <Route
              path="/dashboard-ultrapro/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard />
                </Suspense>
              }
            />

            <Route
              path="/dashboard-ultimate/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardaverage />
                </Suspense>
              }
            />
            <Route
              path="/dashboard-vanilla/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardultimate />
                </Suspense>
              }
            />
            <Route
              path="/command-center/dashboard-ultrapro/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard />
                </Suspense>
              }
            />

            <Route
              path="/command-center/dashboard-ultimate/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardaverage />
                </Suspense>
              }
            />
            <Route
              path="/command-center/dashboard-vanilla/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardultimate />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
