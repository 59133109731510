import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Navigation from "./navigation";
function App() {

  return (
    <>
      <div className="App">
          <BrowserRouter>
            <Navigation />
            <ToastContainer />
          </BrowserRouter>
      </div>
    </>
  );
}

export default App;
